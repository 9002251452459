import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    DatePicker,
    Form,
    Spin,
    Row,
    Col,
    Table,
} from "antd";
import moment from "moment";
import * as XLSX from 'xlsx';
import Can from "../../../security/Can";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import CodeComptableService from "../../../services/codeComptable.service";


const { RangePicker } = DatePicker;

const JournaleVente = () => {
    const [datasource, setDataSource] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [datasourcefilter, setDataSourcefilter] = useState([]);
    const [filterdate, setFilterDate] = useState(false);
    const [filteplage, setFiltePlage] = useState(false);
    const [date, setDate] = useState('');
    const [plage, setPlage] = useState('');


    useEffect(() => {
        getdata()
    }, []);

    const getdata = async () => {
        setLoading(true);
        const res = await CodeComptableService.getdatatabel();
        setLoading(false);
        setDataSourcefilter(res.data);
        return setDataSource(res.data);
    }


    const columns = [
        {
            title: 'N° Compte',
            dataIndex: 'code',
            key: 'code',
            width: 100,
        },
        {
            title: 'Désignation de code',
            dataIndex: 'designation',
            key: 'designation',
            width: 100,
        },
        {
            title: 'Reference facture',
            dataIndex: 'reference',
            key: 'reference',
            width: 100,
        },
        {
            title: 'Client',
            dataIndex: 'client',
            key: 'client',
            width: 100,
        },
        {
            title: 'Date facture',
            dataIndex: 'data',
            key: 'data',
            width: 100,
        },
        {
            title: 'Montant',
            dataIndex: 'base',
            key: 'base',
            width: 100,
        }
    ]

    const dateChange = (selectedDate) => {
        // console.log(selectedDate)
        if (selectedDate) {
            const formattedDate = moment(selectedDate).toISOString();
            const dataFiltered = datasource.filter((item) => item.data.includes(formattedDate.split('T')[0]));
            setDataSourcefilter(dataFiltered);
            setFiltePlage(true);
            setDate(formattedDate);
        } else {
            setDataSourcefilter(datasource);
            setFiltePlage(false);
            setDate('');
        }
    };

    const dateChanges = (selectedDate) => {


        if (selectedDate) {
            const Datedebut = moment(selectedDate[0]).toISOString();
            const Datefin = moment(selectedDate[1]).toISOString();

            const dataFiltered = datasource.filter((item) => item.data.split('T')[0] >= Datedebut.split('T')[0] && item.data.split('T')[0] <= Datefin.split('T')[0]);
            setDataSourcefilter(dataFiltered);
            setFilterDate(true);
            setPlage(Datedebut.split('T')[0] + '/' + Datefin.split('T')[0])
        } else {
            setDataSourcefilter(datasource);
            setFilterDate(false);
            setPlage('');
        }

    };

    const getColumnTitles = (column) => {
        if (column.children) {
            return column.children.flatMap((child) => getColumnTitles(child));
        } else {
            return column.title;
        }
    };
    const exportToExcel = () => {

        const header = columns.flatMap((col) => getColumnTitles(col));

        const fakeheader = ['code', 'designation', 'reference', 'client', 'data', 'base']
        const wsData = datasourcefilter.map((item) => fakeheader.map((key) => item[key]));
        const ws = XLSX.utils.aoa_to_sheet([header, ...wsData]);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Save the workbook
        console.log()
        if (date) {
            const fileName = `Journal Vente-${date.split('T')[0]}.xlsx`;
            XLSX.writeFile(wb, fileName);

        } else if (plage) {
            const fileName = `Journal Vente-${plage}.xlsx`;
            XLSX.writeFile(wb, fileName);
        } else {
            const fileName = 'Journal Vente.xlsx';
            XLSX.writeFile(wb, fileName);
        }

    };
    return (
        <>
            {Can("28_1") ? (
                <Spin spinning={isLoading} size="large">
                    <Card
                        title={"Journal de Vente"}
                    >
                        <div>
                            <Row>
                                <Col span={8}>
                                    <Form.Item
                                        label="Filtre par jour : "
                                        name="dated"
                                        style={{ width: '90%' }}
                                    >
                                        <DatePicker placeholder=" " size="large" className="w-100"
                                            onChange={(e) => dateChange(e)}
                                            disabled={filterdate}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="Filtre par plage: "
                                        name="datef"
                                        style={{ width: '95%' }}
                                    >
                                        <RangePicker placeholder=" " size="large" className="w-100"
                                            onChange={(e) => dateChanges(e)}
                                            disabled={filteplage}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6} style={{ textAlign: 'right' }}>
                                    <Button type="primary" onClick={exportToExcel}>Export Excel</Button>
                                </Col>
                            </Row>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={datasourcefilter}
                            bordered
                            pagination={{ position: ["bottomCenter"], defaultPageSize: 50 }}
                            size="middle"
                            sticky={{ offsetHeader: 0 }}
                        />
                    </Card>
                </Spin>
            ) : (
                <NotAuthorized></NotAuthorized>
            )}
        </>
    )
}

export default JournaleVente;