import React, { useEffect, useState } from "react";
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Modal,
    Row,
    Select,
    Spin,
    Switch,
    Tag,
    Tooltip,
    Checkbox,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import TvaService from "../../../../services/tva.service";
import CodeComptableService from "../../../../services/codeComptable.service";
import AppTable from "../../../uiHelpers/AppTabe";

const Codecomptable = () => {
    const [codeComptableForm] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [codecomptableModal, setCodeComptableModal] = useState(false);
    const [tva, SetTva] = useState([]);
    const [datatable, SetDataTable] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();


    useEffect(() => {
        getTva();
        getdata();
    }, []);

    const getdata = async () => {
        setLoading(true);
        const res = await CodeComptableService.getCodeComptable();
        setLoading(false);
        return SetDataTable(res.data);
    }

    const getTva = async () => {
        setLoading(true);
        const res = await TvaService.getActiveTva();
        setLoading(false);
        return SetTva(res.data);
    }
    const showaAddModal = () => {
        setCodeComptableModal(true);
    };
    const addCodeComptable = async (values) => {
        setLoading(true);
        if (editMode === false) {
            CodeComptableService.addCodecomptable(values)
                .then(res => res ? message.success("Code comptable créé avec succès!!!!") : message.error("error!!!!"))
        } else {
            CodeComptableService.editeCodecomptable(updateId, values)
                .then(res => res ? message.success("Code comptable modifier avec succès!!!!") : message.error("error!!!!"))
        }
        handleCancel();
        getdata();
        setLoading(false);
    };

    const handleCancel = () => {
        setCodeComptableModal(false);
        clearData();
    }

    const clearData = () => {
        codeComptableForm.resetFields();
        setEditMode(false);
        setUpdateId(null);
    };

    const onChangetva = (e) => {
        if (e) {
            codeComptableForm.setFieldsValue({
                timbre: '',
                mnet: ''
            })
        }
    }

    const onChangetembre = (e) => {
        if (e) {
            codeComptableForm.setFieldsValue({
                tva: '',
                mnet: ''
            })
        }
    }

    const onChangemntt = (e) => {
        if (e) {
            codeComptableForm.setFieldsValue({
                timbre: '',
                tva: ''
            })
        }
    }

    const updateMode = (record) => {
        setCodeComptableModal(true);
        codeComptableForm.setFieldsValue(record);
        setUpdateId(record.id);
        setEditMode(true);
    };

    const Columns = [
        {
            title: "N° de compte",
            dataIndex: "code",
            sorter: (a, b) => a.code.localeCompare(b.code),
            width: "15%",
        },
        {
            title: "Désignation",
            dataIndex: "designation",
            width: "20%",
            sorter: (a, b) => a.designation.localeCompare(b.designation),
        },
        {
            title: "Type",
            dataIndex: "type",
            width: "10%",
            sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
            title: "TVA",
            dataIndex: "tva",
            width: "15%",
            sorter: (a, b) => a.tva.localeCompare(b.tva),
        },
        {
            title: "Timbre",
            dataIndex: "timbre",
            width: "15%",
            sorter: (a, b) => a.timbre.localeCompare(b.timbre),
        },
        {
            title: "Montant Net",
            dataIndex: "mnet",
            width: "15%",
            render: (text, record) => (<span>{text == 1 ? 'Oui' : 'Non'}</span>)
        },
        {
            title: "Action",
            key: "action",
            width: "20%",
            render: (text, record) => (
                <div>
                    <Tooltip title="Mettre à jour">
                        <Button
                            className="mx-1"
                            type="dashed"
                            shape="circle"
                            //   disabled={!Can("4_3")}
                            onClick={() => updateMode(record)}
                            icon={<EditOutlined />}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <Spin spinning={isLoading} size="large">
            <Card

                title={"Code Comptable"}
                extra={
                    <Button
                        className="mx-2"
                        shape="round"
                        type="primary"
                        //   disabled={!Can("4_2")}
                        onClick={() => showaAddModal()}
                    >
                        Ajouter un Code Comptable
                    </Button>
                }
            ></Card>
            <AppTable data={datatable} columns={Columns} />
            <Form
                layout="vertical"
                form={codeComptableForm}
                onFinish={addCodeComptable}
            >
                <Modal
                    visible={codecomptableModal}
                    onOk={codeComptableForm.submit}
                    onCancel={handleCancel}
                    getContainer={false}
                    okText="Enregistrer"
                    cancelText="Annuler"
                    centered
                    width={750}
                    title={
                        <>
                            <div className="d-flex justify-content-between ">
                                <div className="my-0">
                                    {editMode
                                        ? "Modifier le code comptable"
                                        : "Ajouter un code comptable"}
                                </div>
                            </div>
                        </>
                    }
                >
                    <Row>
                        <Col span={12} className="px-2">
                            <Form.Item
                                label="N° de compte"
                                name="code"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Champ obligatoire avec maximum de caractère 100 !",
                                        max: 99,
                                    },
                                ]}
                            >
                                <Input size="large" type="text" maxLength={100} />
                            </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                            <Form.Item
                                label="Désignation"
                                name="designation"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Champ obligatoire avec maximum de caractère 255 !",
                                        max: 99,
                                    },
                                ]}
                            >
                                <Input size="large" type="text" maxLength={100} />
                            </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                            <Form.Item
                                label="Type"
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Champ obligatoire avec maximum de caractère 255 !",
                                        max: 99,
                                    },
                                ]}
                            >
                                <Select size="large" dropdownStyle={{ position: "fixed" }}>
                                    <Select.Option key={1} value='vente'>
                                        Vente
                                    </Select.Option>
                                    <Select.Option key={2} value='achat'>
                                        Achat
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                            <Form.Item
                                label="TVA"
                                name="tva"
                                rules={[
                                    {
                                        required: false,
                                        message: false
                                    },
                                ]}
                            >
                                <Select size="large" dropdownStyle={{ position: "fixed" }} onChange={e => onChangetva(e)}>

                                    {tva.map((tva) => (
                                        <Select.Option key={tva.id} value={tva.valeur}>
                                            {tva.titre}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                            <Form.Item
                                label="Timbre"
                                name="timbre"
                                rules={[
                                    {
                                        required: false,
                                        message:
                                            "Champ obligatoire avec maximum de caractère 255 !",
                                        max: 99,
                                    },
                                ]}
                            >
                                <Input size="large" type="text" maxLength={100} onChange={e => onChangetembre(e)} />
                            </Form.Item>
                        </Col>
                        <Col span={12} className="px-2">
                            <Form.Item
                                label="Montant Net"
                                name="mnet"
                                valuePropName="checked"
                            >
                                <Checkbox onChange={e => onChangemntt(e)}></Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Modal>
            </Form>
        </Spin>

    )
};
export default Codecomptable;