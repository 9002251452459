import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Spin,
  Row,
  Col,
  Table,
} from "antd";
import FactureService from "../../../services/factureVente.service";
import moment from "moment";
import * as XLSX from 'xlsx';
import Can from "../../../security/Can";
import NotAuthorized from "../../uiHelpers/NotAuthorized";

const { RangePicker } = DatePicker;


const Vente = () => {
  const [isLoading, setLoading] = useState(false);
  const [datasource, setDataSource] = useState([]);
  const [datasourcefilter, setDataSourcefilter] = useState([]);
  const [rowdata, setRowData] = useState([]);
  const [filterdate, setFilterDate] = useState(false);
  const [filteplage, setFiltePlage] = useState(false);
  const [date, setDate] = useState('');
  const [plage, setPlage] = useState('');



  useEffect(() => {
    getdata()
  }, []);

  const getdata = async () => {
    setLoading(true)
    try {
      const finaldata = []
      let daylexo = 0
      let daybase7 = 0
      let daytax7 = 0
      let daybase19 = 0
      let daytaxe19 = 0
      let daytimbre = 0
      let dayltotal = 0
      let monthlexo = 0
      let monthbase7 = 0
      let monthtax7 = 0
      let monthbase19 = 0
      let monthtaxe19 = 0
      let monthtimbre = 0
      let monthltotal = 0
      let lastdates

      const moins = {
        1: 'Janvier',
        2: 'Février',
        3: 'Mars',
        4: 'Avril',
        5: 'Mai',
        6: 'Juin',
        7: 'Juillet',
        8: 'Août',
        9: 'Septembre',
        10: 'Novembre',
        12: 'Décembre'
      }

      const response = await FactureService.getFacturestatics();
      setRowData(response.data);
      for (let [index, data] of response.data.entries()) {
        const nextIndex = index - 1;
        const nextData = nextIndex < response.data.length ? response.data[nextIndex] : null;
        const actuelledate = data.date.split('T');
        const nextdate = nextData != null ? nextData.date.split('T') : null
        lastdates = actuelledate ? actuelledate[0] : ' '
        const filteredTvaResult = await filterTva(data);
        let resp = {
          date: data.date.split('T')[0],
          nfacture: data.reference,
          client: data.produit_facture_ventes[0].client.registre_commerce,
          libelle: `Fact ${data.produit_facture_ventes[0].client.nom}  ${data.produit_facture_ventes[0].client.prenom}`,
          vexonere: filteredTvaResult.find(x => x.tva === 0) ? filteredTvaResult.find(x => x.tva === 0).base : ' ',
          base7: filteredTvaResult.find(x => x.tva === 7) ? filteredTvaResult.find(x => x.tva === 7).base : ' ',
          taxe7: filteredTvaResult.find(x => x.tva === 7) ? filteredTvaResult.find(x => x.tva === 7).montant : ' ',
          base19: filteredTvaResult.find(x => x.tva === 19) ? filteredTvaResult.find(x => x.tva === 19).base : ' ',
          taxe19: filteredTvaResult.find(x => x.tva === 19) ? filteredTvaResult.find(x => x.tva === 19).montant : ' ',
          recup: ' ',
          perdu: ' ',
          timbre: '1.000',
          tfacture: data.montant_total

        }

        if (nextData != null && actuelledate[0].split('-')[2] !== nextdate[0].split('-')[2]) {
          finaldata.push({

            date: ' ',
            nfacture: ' ',
            client: 'Totale:',
            libelle: nextdate[0],
            vexonere: daylexo.toFixed(3),
            base7: daybase7.toFixed(3),
            taxe7: daytax7.toFixed(3),
            base19: daybase19.toFixed(3),
            taxe19: daytaxe19.toFixed(3),
            recup: ' ',
            perdu: ' ',
            timbre: daytimbre.toFixed(3),
            tfacture: dayltotal.toFixed(3)

          })
          monthlexo = monthlexo + daylexo
          monthbase7 = monthbase7 + daybase7
          monthtax7 = monthtax7 + daytax7
          monthbase19 = monthbase19 + daybase19
          monthtaxe19 = monthtaxe19 + daytaxe19
          monthtimbre = monthtimbre + daytimbre
          monthltotal = monthltotal + dayltotal
          daylexo = 0;
          daybase7 = 0;
          daytax7 = 0;
          daybase19 = 0;
          daytaxe19 = 0;
          daytimbre = 0;
          dayltotal = 0;
        }
        if (nextData != null && actuelledate[0].split('-')[1] !== nextdate[0].split('-')[1]) {
          finaldata.push({

            date: ' ',
            nfacture: 'Totaux du mois de:',
            client: ' ',
            libelle: moins[Number(nextdate[0].split('-')[1])] + '-' + nextdate[0].split('-')[0],
            vexonere: monthlexo.toFixed(3),
            base7: monthbase7.toFixed(3),
            taxe7: monthtax7.toFixed(3),
            base19: monthbase19.toFixed(3),
            taxe19: monthtaxe19.toFixed(3),
            recup: ' ',
            perdu: ' ',
            timbre: monthtimbre.toFixed(3),
            tfacture: monthltotal.toFixed(3)

          })
        }
        // test
        daylexo = daylexo + Number(filteredTvaResult.find(x => x.tva === 0) ? filteredTvaResult.find(x => x.tva === 0).base : 0);
        daybase7 = daybase7 + Number(filteredTvaResult.find(x => x.tva === 7) ? filteredTvaResult.find(x => x.tva === 7).base : 0);
        daytax7 = daytax7 + Number(filteredTvaResult.find(x => x.tva === 7) ? filteredTvaResult.find(x => x.tva === 7).montant : 0);
        daybase19 = daybase19 + Number(filteredTvaResult.find(x => x.tva === 19) ? filteredTvaResult.find(x => x.tva === 19).base : 0);
        daytaxe19 = daytaxe19 + Number(filteredTvaResult.find(x => x.tva === 19) ? filteredTvaResult.find(x => x.tva === 19).montant : 0);
        dayltotal = dayltotal + Number(data.montant_total)
        daytimbre += 1

        finaldata.push(resp)

      }


      finaldata.push({
        date: ' ',
        nfacture: ' ',
        client: 'Totale:',
        libelle: lastdates,
        vexonere: daylexo.toFixed(3),
        base7: daybase7.toFixed(3),
        taxe7: daytax7.toFixed(3),
        base19: daybase19.toFixed(3),
        taxe19: daytaxe19.toFixed(3),
        recup: ' ',
        perdu: ' ',
        timbre: daytimbre.toFixed(3),
        tfacture: dayltotal.toFixed(3)

      })
      setDataSourcefilter(finaldata)
      setDataSource(finaldata)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const composedata = async (composedata) => {
    setLoading(true)
    try {
      const finaldata = []
      let daylexo = 0
      let daybase7 = 0
      let daytax7 = 0
      let daybase19 = 0
      let daytaxe19 = 0
      let daytimbre = 0
      let dayltotal = 0
      let monthlexo = 0
      let monthbase7 = 0
      let monthtax7 = 0
      let monthbase19 = 0
      let monthtaxe19 = 0
      let monthtimbre = 0
      let monthltotal = 0
      let lastdates


      const moins = {
        1: 'Janvier',
        2: 'Février',
        3: 'Mars',
        4: 'Avril',
        5: 'Mai',
        6: 'Juin',
        7: 'Juillet',
        8: 'Août',
        9: 'Septembre',
        10: 'Novembre',
        12: 'Décembre'
      }

      for (let [index, data] of composedata.entries()) {

        const nextIndex = index - 1;
        const nextData = nextIndex < composedata.length ? composedata[nextIndex] : null;
        const actuelledate = data.date.split('T');
        const nextdate = nextData != null ? nextData.date.split('T') : null
        lastdates = actuelledate ? actuelledate[0] : ' '

        const filteredTvaResult = await filterTva(data);
        let resp = {
          date: data.date.split('T')[0],
          nfacture: data.reference,
          client: data.produit_facture_ventes[0].client.registre_commerce,
          libelle: `Fact ${data.produit_facture_ventes[0].client.nom}  ${data.produit_facture_ventes[0].client.prenom}`,
          vexonere: filteredTvaResult.find(x => x.tva === 0) ? filteredTvaResult.find(x => x.tva === 0).base : ' ',
          base7: filteredTvaResult.find(x => x.tva === 7) ? filteredTvaResult.find(x => x.tva === 7).base : ' ',
          taxe7: filteredTvaResult.find(x => x.tva === 7) ? filteredTvaResult.find(x => x.tva === 7).montant : ' ',
          base19: filteredTvaResult.find(x => x.tva === 19) ? filteredTvaResult.find(x => x.tva === 19).base : ' ',
          taxe19: filteredTvaResult.find(x => x.tva === 19) ? filteredTvaResult.find(x => x.tva === 19).montant : ' ',
          recup: ' ',
          perdu: ' ',
          timbre: '1.000',
          tfacture: data.montant_total

        }

        if (nextData != null && actuelledate[0].split('-')[2] !== nextdate[0].split('-')[2]) {
          finaldata.push({

            date: ' ',
            nfacture: ' ',
            client: 'Totale:',
            libelle: nextdate[0],
            vexonere: daylexo.toFixed(3),
            base7: daybase7.toFixed(3),
            taxe7: daytax7.toFixed(3),
            base19: daybase19.toFixed(3),
            taxe19: daytaxe19.toFixed(3),
            recup: ' ',
            perdu: ' ',
            timbre: daytimbre.toFixed(3),
            tfacture: dayltotal.toFixed(3)

          })
          monthlexo = monthlexo + daylexo
          monthbase7 = monthbase7 + daybase7
          monthtax7 = monthtax7 + daytax7
          monthbase19 = monthbase19 + daybase19
          monthtaxe19 = monthtaxe19 + daytaxe19
          monthtimbre = monthtimbre + daytimbre
          monthltotal = monthltotal + dayltotal
          daylexo = 0;
          daybase7 = 0;
          daytax7 = 0;
          daybase19 = 0;
          daytaxe19 = 0;
          daytimbre = 0;
          dayltotal = 0;
        }
        if (nextData != null && actuelledate[0].split('-')[1] !== nextdate[0].split('-')[1]) {
          finaldata.push({

            date: ' ',
            nfacture: 'Totaux du mois de:',
            client: ' ',
            libelle: moins[Number(nextdate[0].split('-')[1])] + '-' + nextdate[0].split('-')[0],
            vexonere: monthlexo.toFixed(3),
            base7: monthbase7.toFixed(3),
            taxe7: monthtax7.toFixed(3),
            base19: monthbase19.toFixed(3),
            taxe19: monthtaxe19.toFixed(3),
            recup: ' ',
            perdu: ' ',
            timbre: monthtimbre.toFixed(3),
            tfacture: monthltotal.toFixed(3)

          })
        }
        // test
        daylexo = daylexo + Number(filteredTvaResult.find(x => x.tva === 0) ? filteredTvaResult.find(x => x.tva === 0).base : 0);
        daybase7 = daybase7 + Number(filteredTvaResult.find(x => x.tva === 7) ? filteredTvaResult.find(x => x.tva === 7).base : 0);
        daytax7 = daytax7 + Number(filteredTvaResult.find(x => x.tva === 7) ? filteredTvaResult.find(x => x.tva === 7).montant : 0);
        daybase19 = daybase19 + Number(filteredTvaResult.find(x => x.tva === 19) ? filteredTvaResult.find(x => x.tva === 19).base : 0);
        daytaxe19 = daytaxe19 + Number(filteredTvaResult.find(x => x.tva === 19) ? filteredTvaResult.find(x => x.tva === 19).montant : 0);
        dayltotal = dayltotal + Number(data.montant_total)
        daytimbre += 1
        // console.log(filteredTvaResult);
        finaldata.push(resp)
      }
      finaldata.push({
        date: ' ',
        nfacture: ' ',
        client: 'Totale:',
        libelle: lastdates,
        vexonere: daylexo.toFixed(3),
        base7: daybase7.toFixed(3),
        taxe7: daytax7.toFixed(3),
        base19: daybase19.toFixed(3),
        taxe19: daytaxe19.toFixed(3),
        recup: ' ',
        perdu: ' ',
        timbre: daytimbre.toFixed(3),
        tfacture: dayltotal.toFixed(3)

      })

      // console.log(finaldata)
      setDataSourcefilter(finaldata)
      // setDataSource(finaldata)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const filterTva = async (ficheData) => {
    const fodec = 1
    const fodecValue = ficheData.fodec === 0 ? 0 : fodec.valeur / 100;
    const filtredTva = [];
    const tvas = [];
    for (const element of ficheData.produit_facture_ventes) {
      if (!filtredTva.includes(element.tva)) filtredTva.push(element.tva);
    }

    for (const tva of filtredTva) {
      var montant = 0;
      var base = 0;
      for (const element of ficheData.produit_facture_ventes) {
        if (tva === element.tva) {
          // console.log(Number(element.total_prix_HT));
          base += Number(element.total_prix_HT);
        }
      }
      base = Number(base + (base * fodecValue)).toFixed(3);
      montant = Number(base * tva / 100).toFixed(3);
      tvas.push({ tva, base, montant });
    }
    // console.log(tvas);
    return tvas;
  }

  const getRowClassName = (record) => {
    if (record.client == 'Totale:') {
      return 'empty-date-row'
    } else if (record.nfacture == 'Totaux du mois de:') {
      return 'empty-libille-row'
    }  // return record.libelle === ' ' ? 'empty-date-row' : 'empty-libille-row';
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 100,
    },
    {
      title: 'N° Facture',
      dataIndex: 'nfacture',
      key: 'nfacture',
      width: 90,
      render: (text, record) => {
        // If it's the "Totaux du mois de:" row, merge the cells
        if (text === 'Totaux du mois de:') {
          return {
            children: text,
            props: {
              colSpan: 2, // Merge the cell horizontally
            },
          };
        }
        return {
          children: text,
        };
      },
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      width: 75,
      render: (text, record) => {
        // If it's the "Totaux du mois de:" row, hide the cell
        if (record.nfacture === 'Totaux du mois de:') {
          return {
            props: {
              colSpan: 0, // Merge the cell horizontally
            },
          }
        }
        return {
          children: text,
        };
      },
    },
    {
      title: 'Libelle',
      dataIndex: 'libelle',
      key: 'libelle',
      width: 250,
    },
    {
      title: 'Ventes Exonerees',
      dataIndex: 'vexonere',
      key: 'vexonere',
      width: 85,
    },
    {
      title: 'Ventes 7,00%',
      children: [
        {
          title: 'Base',
          dataIndex: 'base7',
          key: 'base7',
          width: 73,
        },
        {
          title: 'Taxe',
          dataIndex: 'taxe7',
          key: 'taxe7',
          width: 73,
        },
      ],
    },
    {
      title: 'Ventes 19,00%',
      children: [
        {
          title: 'Base',
          dataIndex: 'base19',
          key: 'base19',
          width: 73,
        },
        {
          title: 'Taxe',
          dataIndex: 'taxe19',
          key: 'taxe19',
          width: 73,
        },
      ],
    },
    {
      title: 'Emballages',
      children: [
        {
          title: 'Recup',
          dataIndex: 'recup',
          key: 'recup',
          // width: 200,
          width: 67,

        },
        {
          title: 'Perdu',
          dataIndex: 'perdu',
          key: 'perdu',
          width: 67,

        },
      ],
    },
    {
      title: 'Timbre',
      dataIndex: 'timbre',
      key: 'timbre',
      width: 80,
    },
    {
      title: 'Total Facture',
      dataIndex: 'tfacture',
      key: 'tfacture',
      width: 82,
    },
  ]

  const dateChange = (selectedDate) => {
    // console.log(selectedDate)
    if (selectedDate) {
      const formattedDate = moment(selectedDate).toISOString();
      const dataFiltered = datasourcefilter.filter((item) => {
        if (item.date != ' ') {
          return item.date.includes(formattedDate.split('T')[0]);
        } else if (item.date == ' ') {
          return item.libelle.includes(formattedDate.split('T')[0])
        }
      });
      setDataSourcefilter(dataFiltered);
      setFiltePlage(true);
      setDate(formattedDate);
    } else {
      setDataSourcefilter(datasource);
      setFiltePlage(false);
      setDate('');
    }
  };

  const dateChanges = (selectedDate) => {


    if (selectedDate) {
      const Datedebut = moment(selectedDate[0]).toISOString();
      const Datefin = moment(selectedDate[1]).toISOString();

      const dataFiltered = rowdata.filter((item) => item.date.split('T')[0] >= Datedebut.split('T')[0] && item.date.split('T')[0] <= Datefin.split('T')[0] );
      composedata(dataFiltered);
      setFilterDate(true);
      setPlage(Datedebut.split('T')[0] +'/'+ Datefin.split('T')[0])
    } else {
      setDataSourcefilter(datasource);
      setFilterDate(false);
      setPlage('');
    }

  };

  const getColumnTitles = (column) => {
    if (column.children) {
      return column.children.flatMap((child) => getColumnTitles(child));
    } else {
      return column.title;
    }
  };

  // Function to export table to Excel
  const exportToExcel = () => {

    const header = columns.flatMap((col) => getColumnTitles(col));

    const fakeheader = ['date', 'nfacture', 'client', 'libelle', 'vexonere', 'base7', 'taxe7', 'base19', 'taxe19', 'recup', 'perdu', 'timbre', 'tfacture']
    const vente7Row = ["Date", "N° Facture", "Client", "Libelle", "Ventes Exonerees", "Ventes 7,00%", "", "Ventes 19,00%", "", "Emballages", "", "Timbre", "Total Facture"]
    const wsData = datasourcefilter.map((item) => fakeheader.map((key) => item[key]));
    const ws = XLSX.utils.aoa_to_sheet([vente7Row, header, ...wsData]);

    header.forEach((col, index) => {
      ws[XLSX.utils.encode_cell({ r: 1, c: index })].s = { fill: { fgColor: { rgb: '0000FF' } }, font: { color: { rgb: 'FFFFFF' }, bold: true } };
    });

    ws['!merges'] = [
      { s: { r: 0, c: 5 }, e: { r: 0, c: 6 } },
      { s: { r: 0, c: 7 }, e: { r: 0, c: 8 } },
      { s: { r: 0, c: 9 }, e: { r: 0, c: 10 } },
      { s: { r: 1, c: 0 }, e: { r: 0, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
      { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },
      { s: { r: 0, c: 3 }, e: { r: 1, c: 3 } },
      { s: { r: 0, c: 4 }, e: { r: 1, c: 4 } },
      { s: { r: 0, c: 11 }, e: { r: 1, c: 11 } },
      { s: { r: 0, c: 12 }, e: { r: 1, c: 12 } },
    ];


    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Save the workbook
    console.log()
    if (date) {
    const fileName = `Vente-${date.split('T')[0]}.xlsx`;
    XLSX.writeFile(wb, fileName);

    }else if (plage){
      const fileName = `Vente-${plage}.xlsx`;
      XLSX.writeFile(wb, fileName);
    }else{
      const fileName = 'Vente.xlsx';
      XLSX.writeFile(wb, fileName);
    }

  };

  return (
    <>
      {Can("28_1") ? (
        <Spin spinning={isLoading} size="large">
          <Card
            title={"Regroupement de Vente"}
          >
            <div>
              <Row>
                <Col span={8}>
                  <Form.Item
                    label="Filtre par jour : "
                    name="dated"
                    style={{ width: '90%' }}
                  >
                    <DatePicker placeholder=" " size="large" className="w-100"
                      onChange={(e) => dateChange(e)}
                      disabled= {filterdate}
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    label="Filtre par plage: "
                    name="datef"
                    style={{ width: '95%' }}
                  >
                    <RangePicker placeholder=" " size="large" className="w-100"
                      onChange={(e) => dateChanges(e)}
                      disabled= {filteplage}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                  <Button type="primary" onClick={exportToExcel}>Export Excel</Button>
                </Col>
              </Row>
            </div>

            <Table
              columns={columns}
              dataSource={datasourcefilter}
              bordered
              pagination={{ position: ["bottomCenter"], defaultPageSize: 50 }}
              size="middle"
              // scroll={{ x: 'calc(700px + 50%)', y: 240 }}
              // scroll={{ y: 2000 }}
              rowClassName={getRowClassName}
              sticky={{ offsetHeader: 0 }}
            />
          </Card>
        </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  )

}

export default Vente;
