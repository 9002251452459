import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    ControlOutlined
} from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    Empty,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
    Table,
    Tabs,
    Tooltip,
} from "antd";


import React, { useEffect, useRef, useState } from "react";

const { Option } = Select;

function Addproduct({ onSearchReference, onSearchLibelle,onSearchGout, data, addprodect }) {
    const [searchReference, setSearchReference] = useState("");
    const [form] = Form.useForm();
    const referenceInputRef = useRef(null);
    const quantiteInputRef = useRef(null);
    const priceInput = useRef(null);
    const categorieInput = useRef(null);
    const [categories, setCategories] = useState([]);

    
    useEffect(() => {
        // Set focus on the reference input when the component mounts
        referenceInputRef.current.focus();
            form.setFieldsValue({ libelle: '' });
            onSearchReference('')

    }, []); 

    useEffect(() => {
        setCategories([])
        const firstReferenceInterne = data.length > 0 ? data[0].reference_interne : null;
        const result = data.every(item => item.reference_interne === firstReferenceInterne);


        if (data.length === 1) {
            form.setFieldsValue({ libelle: data[0].libelle });
            form.setFieldsValue({ price: data[0].produit_ventes[0].prixTTC });
            // form.setFieldsValue({ qantite: 1 });

        } else {
            // form.setFieldsValue({ libelle: '' });
            form.setFieldsValue({ price: '' });
            form.setFieldsValue({ qantite: '' });
            form.setFieldsValue({ categorie: '' });

        }
        if (result && data.length > 1) {
            // console.log(data)
            setCategories(data)
        }


    }, [data]);

    const handleLibilleInputEnter = () => {
        // Move focus to the Quantité input when Enter is pressed in the Reference input
        referenceInputRef.current.focus();
    };

    const handleReferenceInputEnter = () => {
        // Move focus to the Quantité input when Enter is pressed in the Reference input


        if (categories.length > 0) {
            categorieInput.current.focus();

        } else {
            quantiteInputRef.current.focus();
        }
    };

    const handlecategorieInputEnter = () => {
        quantiteInputRef.current.focus();

    }

    const handlepriceInputEnter = () => {
        // Move focus to the Quantité input when Enter is pressed in the Reference input
        priceInput.current.focus();
    };

    const handleaddproduct = () => {
        console.log()
        if ((form.getFieldValue().qantite) > 0){
        addprodect(data[0], form.getFieldsValue().qantite)

        form.setFieldsValue({ libelle: '' });
        form.setFieldsValue({ price: '' });
        form.setFieldsValue({ qantite: '' });
        form.setFieldsValue({ reference: '' });
        form.setFieldsValue({ categorie: '' });
        onSearchReference('')
        referenceInputRef.current.focus();
        setCategories([])
    }else {
        message.error("Veuillez remplir le champ qantité !");
    }
    };

    const setSelectedKeys = (event) => {
        // console.log(event.target.value)
        setSearchReference(event.target.value)
        onSearchReference(event.target.value)
    }

    const setSelectedKeyslibb = (event) => {
       return onSearchLibelle(event.target.value)
        // console.log(event)
    }

    const calculprice = (event) => {
        // console.log(event.target.value)
        form.setFieldsValue({ price: (data[0].produit_ventes[0].prixTTC * event.target.value) });

    }

    const CategoriSelect = (value) => {
        onSearchGout(value)
        const result = data.find(x => x.id === value);
        form.setFieldsValue({ libelle: result.libelle });
        form.setFieldsValue({ price: result.produit_ventes[0].prixTTC });
        handlecategorieInputEnter()
    };


    return (
        <div>
            <Form form={form}>
                <Row>
                    <Col span={5} className="px-2">
                        <Form.Item
                            label="Libelle"
                            name="libelle"
                            onChange={e => setSelectedKeyslibb(e)}
                        >
                            <Input size="large" type="text" maxLength={100}
                                onPressEnter={handleLibilleInputEnter} />
                        </Form.Item>
                    </Col>
                    <Col span={5} className="px-2">
                        <Form.Item
                            label="Refrence"
                            name="reference"
                            onChange={e => setSelectedKeys(e)}
                        >
                            <Input size="large" type="text" maxLength={100}
                                ref={referenceInputRef}
                                onPressEnter={handleReferenceInputEnter} />
                        </Form.Item>
                    </Col>
                    <Col span={6} className="px-2">
                        <Form.Item
                            label="Categorie"
                            name="categorie"
                        >
                            <Select size="large"
                                defaultValue={categories.length > 0 ? categories[0].id : null}
                                disabled={!(categories.length > 0)}
                                onChange={CategoriSelect}
                                ref={categorieInput}>
                                {categories.map(category => (
                                    <Option key={category.id} value={category.id}>
                                        {category.gout}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4} className="px-2">
                        <Form.Item
                            label="Qantité"
                            name="qantite"
                            onChange={e => calculprice(e)}
                        >
                            <Input size="large" type="number" maxLength={50}
                                // onPressEnter={handlepriceInputEnter}
                                onPressEnter={handleaddproduct}
                                ref={quantiteInputRef} />
                        </Form.Item>
                    </Col>
                    <Col span={4} className="px-2">
                        <Form.Item
                        
                            label="Prix"
                            name="price"
                        >
                            <Input size="large" type="text" maxLength={100}
                                ref={priceInput} 
                                disabled/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )

}
export default Addproduct;
