import FactureService from "../../../services/FactureAchat.service";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const FactureAchatPdf = async ({ data }) => {
    const rowsInfo = [];

    rowsInfo.push([
        { text: 'Ref', style: 'tablefirstrow' },
        { text: 'Libellé', style: 'tablefirstrow' },
        { text: 'Quantité', style: 'tablefirstrow' },
        { text: 'P.U HT', style: 'tablefirstrow' },
        { text: 'TVA', style: 'tablefirstrow' },
        { text: 'Remise', style: 'tablefirstrow' },
        { text: 'Total HT', style: 'tablefirstrow' },
        { text: 'Total TTC', style: 'tablefirstrow' },
        { text: 'PVP', style: 'tablefirstrow' },
    ]);

    if (data.produit.length > 0) {
        for (const i of data.produit) {
            rowsInfo.push([
                { text: i.refproduct, alignment: 'left' },
                { text: i.libelle, alignment: 'left' },
                i.quantite,
                { text: i.prixHT, alignment: 'left' },
                i.tva != 0 ? i.tva + ' %' : ' ',
                i.remise != 0 ? i.remise + ' %' : ' ',
                i.total_prix_HT,
                i.total_prix_TTC,
                i.prixvp !== undefined ? (i.prixvp != "0.000" ? i.prixvp : ' ') : ' '
            ]);
        }
    } else {
        rowsInfo.push(["", "", "", "", "", "", "", "", ""]);
    }

    const rowsBase = [];

    rowsBase.push([
        { text: 'Taux', bold: true, alignment: 'center', fontSize: 9 },
        { text: 'Base TVA', bold: true, alignment: 'center', fontSize: 9 },
        { text: 'Mnt TVA', bold: true, alignment: 'center', fontSize: 9 },
    ]);

    if (data.filtredTva.length > 0) {
        for (const i of data.filtredTva) {
            rowsBase.push([
                i.tva + ' %',
                i.base,
                i.montant,
            ]);
        }
    } else {
        rowsBase.push(["", "", ""]);
    }

    const tableBody = [
        [{ text: 'Total', bold: true, fontSize: 9 }, { text: 'Montants', bold: true, fontSize: 9 }],
        [{ text: 'Total HT', bold: true, }, { text: data.ficheData.total_ht + ' ' + data.ficheData.devise, }],
        [{ text: 'TVA', bold: true, }, { text: data.ficheData.tva + ' ' + data.ficheData.devise, }],
        [{ text: 'Total TTC', bold: true, }, { text: data.ficheData.total_ttc + ' ' + data.ficheData.devise, }],
        [{ text: 'Timbre Fiscal', bold: true, }, { text: data.ficheData.totalTimbreFiscal + ' ' + data.ficheData.devise, }],
        [{ text: 'Net à payer', bold: true, }, { text: data.ficheData.montant_total + ' ' + data.ficheData.devise, bold: true }]
    ]

    if (data.ficheData.remise_global && data.ficheData.remise_global > 0) {
        tableBody.splice(5, 0, [{ text: 'Remise', bold: true }, { text: data.ficheData.remise_global + ' %' }]);
    }

    const docDefinition = {
        // a string or { width: number, height: number }
        pageSize: 'A4',

        // by default we use portrait, you can change it to landscape if you wish
        pageOrientation: 'portrait',

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [20, 20, 20, 60],


        content: [
            {
                columns: [
                    {
                        width: '*',
                        image: data.logo,
                        fit: [120, 120], // Adjust the width and height as needed
                        alignment: 'center',
                        margin: [0, -22, 0, 0], // Adjust margins as needed
                    },
                    { width: 30, text: '' },
                    {
                        width: '*',
                        stack: [
                            { text: data.nom, fontSize: 16, bold: true, margin: [0, 10] },
                            { text: 'Référence : ' + data.ficheData.reference, fontSize: 9, },
                            { text: 'Référence Fournisseur : ' + data.ficheData.refeFourni, fontSize: 9, },
                            { text: 'Date : ' + data.ficheData.date.split('T')[0], fontSize: 9, },
                            // Add other text or content as needed
                        ],
                        // margin: [10, 10, 10, 10], // Adjust margins as needed
                    },
                ],
                // margin: [0, 0, 0, -10],
            },
            {
                columns: [
                    {
                        table: {
                            widths: [50, '*'], // Adjust column widths as needed
                            body: [
                                [{ text: data.entreprise.raison_social, bold: true, colSpan: 2, fontSize: 10 }, {}],
                                [{ text: 'MF:', bold: true, fontSize: 10 }, { text: data.entreprise.matricule_fiscal, fontSize: 9 }],
                                [{ text: 'Téléphone: ', bold: true, fontSize: 10 }, { text: data.entreprise.telephone, fontSize: 9 }],
                                [{ text: 'Adresse: ', bold: true, fontSize: 10 }, { text: data.entreprise.adresse, fontSize: 9 }],
                                [{ text: ' ', bold: true, fontSize: 10 }, { text: ' ', fontSize: 9 }],
                            ],
                        },
                        layout: {
                            fillColor: function (rowIndex, node, columnIndex) {
                                return (rowIndex % 2 === 0) ? '#CCCCCC' : '#CCCCCC'; // Set the colors as needed
                            },
                            hLineColor: function (i, node) {
                                return '#CCCCCC'; // Set the color for horizontal lines if needed
                            },
                            vLineColor: function (i, node) {
                                return '#CCCCCC'; // Set the color for vertical lines if needed
                            },
                        },
                    },
                    { width: 30, text: '' },
                    {
                        table: {
                            widths: [80, '*'], // Adjust column widths as needed
                            body: [
                                [{ text: 'Fournisseur', bold: true, colSpan: 2, fontSize: 10 }, {}],
                                [{ text: 'Raison sociale : ', bold: true, fontSize: 10 }, { text: data.fournisseur.raison_sociale, fontSize: 9 }],
                                [{ text: 'Nom et prenom : ', bold: true, fontSize: 10 }, { text: data.fournisseur.prenom + ' ' + data.fournisseur.nom, fontSize: 9 }],
                                [{ text: 'Téléphone: ', bold: true, fontSize: 10 }, { text: data.fournisseur.telephone_pro, fontSize: 9 }],
                                [{ text: 'Adresse: ', bold: true, fontSize: 10 }, { text: data.adresse, fontSize: 9 }],
                            ],
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 1 : 0; // Border width for horizontal lines
                            },
                            vLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 1 : 0; // Border width for vertical lines
                            },
                        }

                    },
                ],
                // margin: [0, -10, 0, 0],
            },
            {
                style: 'simpleTable',
                //color: 'white',

                table: {
                    widths: [40, '*', 40, 40, 20, 40, 40, 40, 30],
                    body: rowsInfo,
                    dontBreakRows: true,
                },
                layout: 'lightHorizontalLines'
            },
            {
                columns: [
                    { width: 250, text: '' },
                    {
                        style: 'simpleTable',
                        table: {
                            widths: [30, '*', '*'],
                            body: rowsBase,
                            dontBreakRows: true,
                        },
                        layout: 'lightHorizontalLines'
                    },
                    { width: 20, text: '' },
                    {
                        style: 'simpleTable2',
                        table: {
                            widths: [50, '*'], // Adjust column widths as needed
                            body: tableBody,
                        },
                        layout: 'lightHorizontalLines'
                    },
                ],
                // margin: [0, -10, 0, 0],
            },
            {
                text: data.lettres,
                margin: [0, 20, 0, 0],
                alignment: 'center',
                bold: true,
                fontSize: 10

            },

        ],
        footer(currentPage, pageCount) {
            return [
                {
                    canvas: [
                        {
                            type: 'line',
                            x1: 40,
                            y1: 10,
                            x2: 550,
                            y2: 10,
                            lineWidth: 1,
                        },
                    ],
                },
                {
                    stack: [
                        {
                            text: data.entreprise.raison_social,
                            alignment: 'center',
                            bold: true,
                            fontSize: 8
                        },
                        {
                            text: `Téléphone : ${data.entreprise.telephone} - Adresse: ${data.entreprise.adresse} - MF: ${data.entreprise.matricule_fiscal} `,
                            alignment: 'center',
                            bold: true,
                            fontSize: 8
                        }
                    ],
                    margin: [0, 5],

                },
                {
                    text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                    alignment: 'left',
                    margin: [40, 0],
                    fontSize: 8
                },
            ];
        },
        styles: {
            simpleTable: {
                margin: [0, 10, 0, 0],
                fontSize: 7,
                alignment: "center"
            },
            simpleTable2: {
                margin: [0, 10, 0, 0],
                fontSize: 7,
                alignment: "left",
            },
            tablefirstrow: {
                bold: true,
                alignment: 'left',
                fontSize: 9
            }
        }
    };
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    // return pdfMake.createPdf(docDefinition).download(`${data.ficheData.reference}.pdf`);
    pdfDocGenerator.getBlob(async (blob) => {
        return await FactureService.exportpdf(blob, `${data.ficheData.reference}.pdf`).toPromise();
    });

};

export default FactureAchatPdf;