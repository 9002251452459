import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addDevis = async ( devis ) => {
  return await axios.post(API_URL + "/devis/" , devis ,{ headers: authHeader() });
};

const getAllDevis = async ( callback ) => {
  callback( await axios.get(API_URL + "/devis/" , { headers: authHeader() }))
};

const getActiveDevis = async ( callback ) => {
  callback( await axios.get(API_URL + "/devis/active" , { headers: authHeader() }))
};



const getDevis = async ( id, callback ) => {
  callback( await axios.get(API_URL + "/devis/" + id , { headers: authHeader() }))
};

const getDevissNames = async (callback) => {
  callback( await axios.get(API_URL + "/devis/names" , { headers: authHeader() }))
}

const getReference = async ( id ) => {
  return axios.get(API_URL + "/devis/ref/" + id , { headers: authHeader() });
};


const updateDevis = (id , devis ) => {
  return axios.put(API_URL + "/devis/" + id , devis, { headers: authHeader() });
};

const deleteDevis = (id) => {
  return axios.delete(API_URL + "/devis/" + id , { headers: authHeader() });
};
const changeDevisStatus = (id,etat) => {
  return axios.post(API_URL + "/devis/change/" + id  ,{etat}, { headers: authHeader() });
};

const getLast = async (callback) => {
  callback( await axios.get(API_URL + "/devis/last", { headers: authHeader() }))
}

const duplicate = async ( id) => {
  return axios.get(API_URL + "/devis/duplicate/" + id , {headers: authHeader()})
}

const exportpdf = async (blob,nom) => {
  const formData = new FormData();
  formData.append('pdf', blob, nom);
  return axios.post(API_URL + "/devis/exportpdf" , formData , {headers: authHeader(),'Content-Type': 'multipart/form-data',})
}

const exportsMethodes = {
  addDevis,
  getAllDevis,
  getDevis,
  updateDevis,
  deleteDevis,
  changeDevisStatus,
  getDevissNames,
  getLast,
  getReference,
  getActiveDevis,
  duplicate,
  exportpdf
};
export default exportsMethodes;