import {
  CheckOutlined, DeleteOutlined, PlusCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col, Divider, Form, Input, InputNumber, message, Modal, notification, Row,
  Spin,
  Tooltip,
  Dropdown,
  Menu
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import currencys from '../../../helpers/currency';
import Can from "../../../security/Can";
import ClientService from "../../../services/client.service";
import DevisService from "../../../services/devis.service";
import EntrepriseService from "../../../services/entreprise.service";
import ProduitService from "../../../services/produit.service";
import TaxeService from "../../../services/taxe.service";
import Addproduct from "../../uiHelpers/Fiches/Addproduct";
import FicheClient from "../../uiHelpers/Fiches/FicheClient";
import FicheDetails from "../../uiHelpers/Fiches/FicheDetails";
import FicheFooter from "../../uiHelpers/Fiches/FicheFooter";
import FicheFournisseur from "../../uiHelpers/Fiches/FicheFournisseur";
import FicheHeader from "../../uiHelpers/Fiches/FicheHeader";
import FicheTableGain from "../../uiHelpers/Fiches/FicheTableGain";
import FicheTableTotaux from "../../uiHelpers/Fiches/FicheTableTotaux";
import FicheTableTva from "../../uiHelpers/Fiches/FicheTableTva";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";
import AddProductModalForm from "../General/AddProductModalForm";
import AddProductModalFormV2 from "../General/AddProductModalFormV2";
import writtenNumber from '/node_modules/written-number/lib/index';
import Text from "antd/lib/typography/Text";
import TableWithFiltresBilder from "../../uiHelpers/TableFiltersbuilder";
import FacturePdf from "../../uiHelpers/Pdfgenerator/Facturepdf";

const { TextArea } = Input;
writtenNumber.defaults.lang = 'fr';


function Devis() {
  const [productsForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [fournisseurs, setFournisseurs] = useState([]);
  const [fournisseur, setFournisseur] = useState(null);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);
  const [entreprise, setEntreprise] = useState({});
  const [visualisationModal, setVisualisationModal] = useState(false);
  const [productsModal, setProductsModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isDarkMode, setDarkMode] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [appliedTaxes, setListAppliedTaxes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [gains, setGain] = useState([]);
  const [productModal , setProductModal] = useState(false);
  const [fodec , setFodec] = useState(false);
  const [writtenTotal,setWrittenTotal] = useState("");
  const [selectedCurrency,setSelectedCurrency] = useState("");
  const [modalTitle, setModalTitle ] = useState("")
  const [modalType,setModalType] = useState();
  // State variable to keep track of all the expanded rows
  // By default, nothing expanded. Hence initialized with empty array.
  const [expandedRows, setExpandedRows] = useState([]);
  // State variable to keep track which row is currently expanded.
  const [expandState, setExpandState] = useState({});
  const [productListfilter, setProductListfilter] = useState([]);
  const [searchReference, setSearchReference] = useState("");




  const history = useHistory();

  const location = useLocation();

  const [prixTotal, setPrixTotal] = useState({
    montant_total: 0,
    total_ttc: 0,
    total_ht: 0,
    fodec: 0,
    tva: 0,
    totalTimbreFiscal: "0.000",
  });

  const isVente = true;

  useEffect(() => {
    loadProduits();
    loadClients();
    loadEntreprise();
    getThemeMode();
    setUpdate();
    return () => {
      clearStates();
    };
  }, []);
  const loadTaxeFodec = async () => {
    const response = await TaxeService.checkTaxeFodec();
    setFodec(response.data);
  }

  const setUpdate = async () => {
    if( location.state?.id ){
      const id = location.state?.id;
      setLoading(true);
      await DevisService.getDevis( id , ( response )=> {
        if (response.status === 200 || 204) {
          const activeFodec = response.data.fodec > 0 ? true : false; 
          setFodec(activeFodec)
          const clientId = response.data.produits_devis[0].clientId;
          const devisInfo = {...response.data }
          devisInfo.date = moment(devisInfo.date);
          devisInfo.client = clientId;
          devisInfo.produits_devis.map((it)=> it.description = it.produit.description )
          productsForm.setFieldsValue(devisInfo);
          loadProduits();
          calculeTotalTaxes(activeFodec);
          setSelectedCurrency(response.data.devise);
          const ids = []
          for (const element of devisInfo.produits_devis) ids.push(element.produitId);
          setSelectedIds(ids);
          setUpdateId(id);
          clientChanged();
          setEditMode(true);
        }else message.error("Une erreur est survenue ! ");
        setLoading(false);
      });
    }else{
      await loadTaxeFodec();
      // generateReference();
      productsForm.setFieldsValue({date : moment(new Date()) } )
    }
  }
  const generateReference = async () => {
    await DevisService.getLast( ( response )=> {
      if (response.status === 200 || 204) {
        if(response.data.length === 0){
          var nbr  = "1";
          var reference = "DV-" + nbr.padStart(4, '0');
        }else{
          const lastRef = response.data.reference;
          const refNbr = (Number(lastRef.substring(3, 7)) + 1 ) + "";
          var reference = "DV-" + refNbr.padStart(4, '0');
        }
        productsForm.setFieldsValue({reference: reference ,date : moment(new Date()) } )
      }else message.error("Une erreur est survenue ! ");
      setLoading(false);
    });
  }

  const loadProduits = async () => {
    setLoading(true);
    const response = await ProduitService.getProduitsMainStore();
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };
  const getProducts = async () => {
    const response = await ProduitService.getProduitsMainStore();
    if (response.status === 200 || 204) return response.data;
    else message.error("Une erreur est survenue ! ");
  };
  
  const initTableData = (produits) => {
    produits = formatteProductsToDataTable(produits);
    setProductList(produits);
    setProductListfilter(produits);

  };

  const formatteProductsToDataTable = (produits) => {
    for (const produit of produits) {
      produit.showType = produit.type ? "Service" : "Produit";
      produit.ttcAchat = produit.produit_achats[0]?.prixTTC;
      produit.htAchat = produit.produit_achats[0]?.prixHT;
      produit.pqte = !produit.type ? produit.stocks[0]?.quantite : "";
      produit.fournisseurId = produit.produit_achats[0]?.fournisseurId;
      produit.showFournisseur = produit.produit_achats[0]?.fournisseur?.raison_sociale ?? "Produit proprietere";
      produit.ttcVente = produit.produit_ventes[0].prixTTC;
      produit.htVente = produit.produit_ventes[0].prixHT;
    }
    return produits;
  };

  const selectProduct = (produit) => {
    const list = selectedIds;
    list.push(produit.id)
    setSelectedIds(list);
    formatteProduct(produit);
  };

  const removeElement = (produitId) => {
    const list = selectedIds;
    setSelectedIds(list.filter((idP) => idP !== produitId));
  };

  const isSelected = (id) => {
    return selectedIds.includes(id);
  };

  const formatteProduct = (produit) => {
    const qte = produit?.quantite ?? 1;
    if (client === null){
      return message.error('Veuillez remplir le champ Client !')
    }
    if( client.exoneration ){

      var prixUniteHt = produit?.produit_ventes[0]?.prixHT;
      var prixUniteTTC = prixUniteHt
      var tva = 0
      var ht =  produit?.produit_ventes[0]?.total_prix_HT ?? Number(prixUniteHt) * Number(qte);
      var ttc = produit?.produit_ventes[0]?.total_prix_TTC ?? Number(prixUniteTTC) * Number(qte);
    }else {
      if( isVente ){
        var prixUniteHt = produit?.produit_ventes[0]?.prixHT;
        var prixUniteTTC = produit?.produit_ventes[0]?.prixTTC;
        var tva = produit?.produit_ventes[0]?.tva
        var ht =  produit?.produit_ventes[0]?.total_prix_HT ?? Number(prixUniteHt) * Number(qte);
        var ttc = produit?.produit_ventes[0]?.total_prix_TTC ?? Number(prixUniteTTC) * Number(qte);
      }
    }
    const remise = produit?.remise ?? 0;
    const product = {
      produitId: produit?.id,
      refproduct:produit.reference_interne,
      libelle: produit?.libelle,
      quantite: Number(qte),
      prixHT: Number(prixUniteHt),
      prixTTC: Number(prixUniteTTC),
      tva: Number(tva),
      remise: Number(remise),
      total_prix_HT: Number(ht).toFixed(3),
      total_prix_TTC: Number(ttc).toFixed(3),
      description: produit.description
    };
    const productList = productsForm.getFieldValue("produits_devis")
      ? productsForm.getFieldValue("produits_devis")
      : [];
    productList.push(product);
    productsForm.setFieldsValue({ produits_devis: productList });
    calculeTotalTaxes();
  };

  const getThemeMode = () => {
    const theme = localStorage.getItem("COLOR_THEME");
    const darkMode = theme === "DARK" ? true : false;
    setDarkMode(darkMode);
  };

  const loadClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) {
      setClients(response.data);
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const reloadClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) {
      setClients(response.data);
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };
  const getClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) {
      return response.data;
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };
  

  const loadEntreprise = async () => {
    setLoading(true);
    const response = await EntrepriseService.getEntreprise();
    if (response.status === 200 || 204) {
      setEntreprise(response.data);
    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  };

  const clearStates = () => {
    setEditMode(false);
    setUpdateId(null);
  };

  const handleCancel = () => {
    setProductsModal(false);
  };


  const priceChanged = (index) => {
    const products = productsForm.getFieldValue("produits_devis");
    const product = products[index];
    const taxe = product.tva;
    const quantite = product.quantite;
    const prixHT = Number(product.prixHT - product.prixHT * (product.remise / 100)).toFixed(3);
    const prixTTC = (Number(prixHT) + Number((prixHT / 100) * taxe)).toFixed(3);
    const total_ht = Number(prixHT * quantite).toFixed(3);
    const total_ttc = Number(prixTTC * quantite).toFixed(3);
    products[index].total_prix_TTC = Number(total_ttc).toFixed(3);
    products[index].total_prix_HT = Number(total_ht).toFixed(3);
    productsForm.setFieldsValue({ products });
    calculeTotalTaxes();
  };

  const calculeTotalTaxes = async (activeFodec) => {
    const products = productsForm.getFieldValue("produits_devis");
    const list = [];
    const listGain = [];
    const isFodecActive = activeFodec ? activeFodec : fodec;
    for (const product of products) {
      // if (product.tva > 0.1) {
        const base = isFodecActive ? 
          Number(product.total_prix_HT) + Number(product.total_prix_HT * 1) / 100 :
          Number(product.total_prix_HT);
        const montantTaxes = isFodecActive ?
          ((Number(product.total_prix_HT) + Number(product.total_prix_HT * 0.01)) * (product.tva / 100)).toFixed(3) :
          (Number(product.total_prix_HT) * (product.tva / 100)).toFixed(3);
        const taxe = {
          tvaPorucentage: product.tva,
          montantTaxes: montantTaxes,
          base: base,
        };
        list.push(taxe);
      // }
      const listProd = productList.length ? productList : await getProducts(); 
      const prod = listProd.find( prod => prod.id === product.produitId);
      const gain_ht = ( product.total_prix_HT  - ( prod?.produit_achats[0]?.prixHT * product.quantite)   ).toFixed(3);
      const gain_ttc = (product.prixTTC - prod?.produit_achats[0]?.prixTTC ) * product.quantite;
      const gain = {gain_ht , gain_ttc};
      listGain.push(gain);
    }
    setGain(listGain);
    setListAppliedTaxes(list);
    calculateTotal(isFodecActive);
  };



  const clientChanged = async () => {
    const clientId = productsForm.getFieldValue("client");
    const clientList = clients.length ? clients : await getClients();
    let cli =  clientList.find((client) => client.id === clientId);
    cli = {
      ...cli,
      lastdate: lastsell(cli),
    };
    setClient( cli );

  };

  function lastsell  (data)  {
    const mergarry = data.produit_facture_ventes.concat(data.produits_ticket_caisses);
    // Find the item with the most recent createdAt date
    const mostRecentItem = mergarry.reduce((acc, current) => {
      const currentCreatedAt = new Date(current.createdAt);
      const accCreatedAt = acc ? new Date(acc.createdAt) : null;

      if (!acc || currentCreatedAt > accCreatedAt) {
        return current;
      } else {
        return acc;
      }
    }, null);

    if (mostRecentItem) {
      return mostRecentItem.createdAt;
    } else {
      return null
    }
    

  }

  const calculateTotal = (fodecActive) => {
    const isFodecActive = fodecActive ? fodecActive : fodec;
    const products = productsForm.getFieldValue("produits_devis");
    const remiseGlobal = productsForm.getFieldValue("remise_global") ? productsForm.getFieldValue("remise_global") : 0;
    const list = [];
    let total_ht = 0;
    let tva = 0;
    let totalTimbreFiscal = 0;
    let total_ttc = 0;

    for (const product of products) {
      const montantTaxes = isFodecActive ? 
        (Number(product.total_prix_HT) + Number(product.total_prix_HT * 0.01)) * (product.tva / 100) :
        (Number(product.total_prix_HT) ) * (product.tva / 100) ;
      total_ht += Number(product.total_prix_HT);
      tva += montantTaxes;
    }
    total_ht = Number(total_ht) - ( Number(total_ht) *  (Number(remiseGlobal) / 100))
    let fodecMontant = isFodecActive ? Number(total_ht) / 100 : 0;
    total_ttc += Number(total_ht) + Number(tva) + Number(fodecMontant);
    let montant_total = (Number(total_ttc) + Number(totalTimbreFiscal)).toFixed(3);
    const total = { montant_total, total_ttc, total_ht, fodec: fodecMontant, tva, totalTimbreFiscal };
    setPrixTotal(total);

    const final = montant_total.toString().split(".");
    const currencyName = currencys.find(c => c.code === productsForm.getFieldValue("devise"));
    setWrittenTotal("Arrêté la présente facture à la somme de " + writtenNumber(final[0]) + " " +currencyName.base + " , " +final[1] + " " + currencyName.sub);

  };

  const getProductId = (index, name) => {
    return productsForm.getFieldValue("produits_devis")[index]?.produitId;
  };

  const addDevis = async () => {
    setLoading(true);
    const product = productsForm.getFieldsValue();
    for (const element of product.produits_devis) {
      const product = productList.find(prod => prod.id === element.produitId );
      // if( product.pqte < element.quantite && product.type !== 1 ) {
      //   showMessage( element.quantite - product.pqte , product.libelle )
      //   setLoading(false)
      //   console.log(product)
      //   console.log(productList)
      //   history.push("/devis_list");
      //   return;
      // } 
      element.clientId = productsForm.getFieldValue("client");
      
    }
    const devis = {...product , ...prixTotal }
    devis.etat = 0;
    devis.remise_global =  devis.remise_global ? devis.remise_global : 0;
    const response = editMode ? 
      await DevisService.updateDevis(updateId,devis) 
      : await DevisService.addDevis(devis);
    if (response.status === 200 || 204) {
      await FacturePdf({ data:response.data });
      if(editMode) message.info("Mise à jour avec succès");
      else message.info("Devis ajouté avec succès");
      history.push("/devis_list");

    } else message.error("Une erreur est survenue ! ");
    setLoading(false);
  }
  const showMessage = ( qte , libelle ) => {
    notification.info({
      message: 'Quantité Indisponible',
      description: 'Vous devez commander au moins ('+ qte +') '+libelle+' .',
      duration: 0,
    });
  }

  const ProduitColumns = [
    {
      title: "Type",
      dataIndex: "showType",
      key: "1",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Libelle",
      dataIndex: "libelle",
      sorter: (a, b) => a.showNom.localeCompare(b.showNom),
    },
    {
      title: "Référence Int",
      dataIndex: "reference_interne",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Fournisseur",
      dataIndex: "showFournisseur",
      sorter: (a, b) => a.showFournisseur - b.showFournisseur,
    },{
      title: "Quantite",
      dataIndex: "pqte",
      sorter: (a, b) => a.pqte - b.pqte,
    },
    {
      title: "Achat TTC",
      dataIndex: "ttcAchat",
      sorter: (a, b) => a.ttcAchat - b.ttcAchat,
    },
    {
      title: "Vente TTC",
      dataIndex: "ttcVente",
      sorter: (a, b) => a.valeur - b.valeur,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Choisir">
            <Button
              disabled={isSelected(record.id)}
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => selectProduct(record)}
              icon={<CheckOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const openProductModal = () => {
    setProductModal(true);
  };
  const onCloseProductModal = async (action) => {
    if (action) {
      const fournisseurId = productsForm.getFieldValue("fournisseur");
      await loadProduits(fournisseurId);
    }
    setProductModal(false);
  };

  const changeCurrency = () => {
    const currency = productsForm.getFieldValue("devise");
    setSelectedCurrency(currency);
  }
  
  const disableForm = () => {
    return selectedIds.length === 0;
  }
  
  const handleEpandRow = (event, index) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(index);

    let obj = {};
    isRowExpanded ? (obj[index] = false) :  (obj[index] = true);
    setExpandState(obj);

    const newExpandedRows = isRowExpanded ?
          currentExpandedRows.filter(id => id !== index) :
          currentExpandedRows.concat(index);

    setExpandedRows(newExpandedRows);
  }

  const getProductMax = (index) => {
    const product = productsForm.getFieldValue("produits_devis");
    const produitId = product[index].produitId;
    const prodStockQuantite = productList.find((it)=> it.id == produitId).stocks[0].quantite
    return prodStockQuantite
  }

  const showProduitModal = (type) => {
    setModalType(type);

    if( type === 0) setModalTitle("Produit")
    else if( type === 1) setModalTitle("Service");
    else setModalTitle("Produit Composé");

    setProductModal(true);
  };
  const getLibelle = (index) => {
    return productsForm.getFieldValue("produits_devis")[index]?.libelle;
  };
  const getRef = (index) => {
    return productsForm.getFieldValue("produits_devis")[index]?.refproduct;
  };
  const getProduitMin = ( index ) =>{
    const libelle = productsForm.getFieldValue("produits_devis")[index]?.libelle;
    if (productList.find((e) => e.libelle = libelle)) {
      return productList.find((e) => e.libelle = libelle).qteMinVente;
    }

  } 

  const getProduitMax = ( index ) =>{
    const libelle = productsForm.getFieldValue("produits_devis")[index]?.libelle;
    if (productList.find((e) => e.libelle = libelle)) {
    return productList.find( (e) => e.libelle = libelle ).qteMaxVente;
  }
  } 

  const handleSearchReference = (reference) => {
    const searchReference = reference.toLowerCase();

    // Filter the data based on the reference_interne field
    const filteredData = productList.filter((product) =>
      product.reference_interne.toLowerCase().includes(searchReference)
    );
    return setProductListfilter(filteredData)
    // setSearchReference(reference);
  };

  const handleSearchLibelle = (reference) => {
  
    const searchReference = reference.toLowerCase();

    // // Filter the data based on the reference_interne field
    const filteredData = productList.filter((product) =>
      product.libelle.toLowerCase().includes(searchReference)
    );

  
    return setProductListfilter(filteredData)
    // setSearchReference(reference);
  };

  const handleAddproduct = (prod, quantity) => {
    const productListt = productsForm.getFieldValue("produits_devis")
      ? productsForm.getFieldValue("produits_devis")
      : [];
    const index = productListt.findIndex(x => x.produitId === prod.id);
    if (index !== -1) {
      const products = productsForm.getFieldValue("produits_devis");
      const product = products[index];
      product.quantite = Number(product.quantite) + Number(quantity);
      priceChanged((index));
    } else {
      selectProduct(prod)
      const products = productsForm.getFieldValue("produits_devis");
      const product = products[products.length - 1];
      product.quantite = quantity;
      priceChanged((products.length - 1));
    }
  }

  const onSearchGout = (id) => {
    const filteredData = productList.filter((product) =>
    product.id === id
  );
    return setProductListfilter(filteredData)
  }

  return (
    <>
    {Can("13_2") ? (
    <Spin  className="px-5" spinning={isLoading} size="large" >
      <Card className="w-100 card-shadow">
        <Form layout="vertical" initialValues={{ devise: "TND", }} form={productsForm} onFinish={addDevis}>
          <Row>
            <FicheHeader
              moduleName={"Devis"}
              showClients={isVente}
              entreprise={entreprise}
              clients={clients}
              fournisseurs={fournisseurs}
              selectedClient={() => clientChanged()}
              reloadClients={() => reloadClients()}
            ></FicheHeader>
          </Row>
          <Row>
            {isVente ? (
              <Col span={8} className="px-2 pt-3">
                <FicheClient client={client}></FicheClient>
              </Col>
            ) : (
              <Col span={8} className="px-2 pt-3">
                <FicheFournisseur fournisseur={fournisseur}></FicheFournisseur>
              </Col>
            )}
            <Col span={8} offset={8} className="px-2 pt-3">
              <FicheDetails 
                currencyChanged={ ()=>  changeCurrency()}
              ></FicheDetails>
            </Col>
          </Row>
              <Row>
                <h5><u>Ajouter Des Produits</u></h5>
                <Col span="24" className="">
                  <Addproduct onSearchReference={handleSearchReference} onSearchLibelle={handleSearchLibelle} addprodect={handleAddproduct} onSearchGout={onSearchGout} data={productListfilter} />

                  <TableWithFiltresBilder data={productListfilter} columns={ProduitColumns} searchReference={searchReference} />
                </Col>
              </Row>
              <br></br>
              <Row>
                <h5><u>Produits Sélectionnés</u></h5>
                <Col span="24" className="pr-4">
                  <table
                    className={` mb-5 table ${isDarkMode ? "table-dark" : ""
                      } `}
                  >
                    <thead className="pl-2">
                      <Row>
                        <Col span={2}>
                          <b>Ref</b>
                        </Col>
                        <Col span={4}>
                          <b>Libelle</b>
                        </Col>


                        <Col span={2}>
                          <b>Quantité</b>
                        </Col>

                        <Col span={2}>
                          <Tooltip title="Quantité Gratuite">
                            <b className="pl-3">QG</b>
                          </Tooltip>
                        </Col>

                        <Col span={1}>
                          <Tooltip title="Quantité Total">
                            <b>QT</b>
                          </Tooltip>
                        </Col>

                        <Col span={2}>
                          <Tooltip title="Prix Unitaire HT">
                            <b>Prix HT</b>
                          </Tooltip>
                        </Col>

                        <Col span={1}>
                          <b>TVA</b>
                        </Col>

                        <Col span={2}>
                          <b>Remise</b>
                        </Col>

                        <Col span={2}>
                          <b>Total HT</b>
                        </Col>

                        <Col span={2}>
                          <b>Total TTC</b>
                        </Col>

                        <Col span={2}>
                          <b>PVP</b>
                        </Col>

                        <Col className="px-3" span={2} style={{ marginLeft: '-5%' }}>
                          {/* <Tooltip title="Ajouter un produit"> */}
                          <Button
                            type="primary"
                            disabled={!client}
                            onClick={() => setProductsModal(true)}
                          > Recherche produits</Button>
                          {/* </Tooltip> */}
                        </Col>
                      </Row>
                    </thead>
                    <tbody>
                      <Form.List name="produits_devis" label="List des addresse de livraisons">
                        {(fields, { add, remove }) => (
                          <>
                            <Row className="pb-1 mb-1 ">
                              {fields.map(
                                ({ key, name, ...restField }, index) => (
                                  <>
                                    <Col className="mb-0 pb-0" span={2}>
                                      {/* {index + 1} */}
                                      <Text className="mb-0 pb-0">
                                        {getRef(index)}
                                      </Text>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "refproduct"]}
                                        style={{ height: 0 }}
                                      >
                                        <Input
                                          type="hidden"
                                          bordered={false}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={4}>
                                      <Text className="mb-0 pb-0">
                                        {getLibelle(index)}
                                      </Text>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "libelle"]}
                                        style={{ height: 0 }}
                                      >
                                        <Input
                                          type="hidden"
                                          bordered={false}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={2}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "quantite"]}
                                      >
                                        <InputNumber
                                          style={{ width: 80 }}
                                          onChange={() => priceChanged(index)}
                                          className="w-125px"
                                          min={getProduitMin(index)}
                                          max={getProduitMax(index)}

                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={2}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "quantiteGratuite"]}
                                      >
                                        <InputNumber
                                          style={{ width: 60 }}
                                          className="w-125px"
                                          min={0}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={1}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "quantiteTotal"]}
                                      >
                                        <InputNumber
                                          className={`${isDarkMode
                                              ? "total-input-dark"
                                              : "total-input-light"
                                            } `}
                                          disabled
                                          bordered={false}
                                          style={{ width: 50 }}
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col className="mb-0 pb-0" span={2}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "prixHT"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Champ obligatoire avec maximum de caractère 100 !",
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          className={`w-125px ${isDarkMode
                                              ? "total-input-dark"
                                              : "total-input-light"
                                            } `}
                                          disabled
                                          bordered={false}
                                          onChange={() => priceChanged(index)}
                                          style={{ width: 120 }}
                                          min={0}
                                          formatter={(value) =>
                                            `${value}`
                                          }
                                          parser={(value) =>
                                            value.replace("", "")
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={1}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "tva"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Champ obligatoire avec maximum de caractère 100 !",
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          className={`w-125px ${isDarkMode
                                              ? "total-input-dark"
                                              : "total-input-light"
                                            } `}
                                          disabled
                                          bordered={false}
                                          onChange={() => priceChanged(index)}
                                          style={{ width: 60 }}
                                          min={0}
                                          max={100}
                                          formatter={(value) => value !== '0' ? `${value}%` : ' '}
                                          parser={(value) =>
                                            value.replace("%", "")
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={2}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "remise"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Champ obligatoire avec maximum de caractère 100 !",
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          className={`w-125px ${isDarkMode
                                              ? "total-input-dark"
                                              : "total-input-light"
                                            } `}

                                          bordered={true}
                                          onChange={() => priceChanged(index)}
                                          min={0}
                                          max={100}
                                          style={{ width: 60 }}
                                          formatter={(value) => `${value}%`}
                                          parser={(value) =>
                                            value.replace("%", "")
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={2}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "total_prix_HT"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Champ obligatoire avec maximum de caractère 100 !",
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          className={` ${isDarkMode
                                              ? "total-input-dark"
                                              : "total-input-light"
                                            } `}
                                          disabled
                                          bordered={false}
                                          style={{ width: 120 }}
                                          min={0}
                                          formatter={(value) =>
                                            `${value}`
                                          }
                                          parser={(value) =>
                                            value.replace("", "")
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={2}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "total_prix_TTC"]}
                                      >
                                        <InputNumber
                                          className={`w-125px ${isDarkMode
                                              ? "total-input-dark"
                                              : "total-input-light"
                                            } `}
                                          disabled
                                          bordered={false}
                                          style={{ width: 120 }}
                                          min={0}
                                          formatter={(value) =>
                                            `${value}`
                                          }
                                          parser={(value) =>
                                            value.replace("", "")
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0" span={2}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "prixvp"]}
                                      >
                                        <InputNumber
                                          className={`w-125px ${isDarkMode
                                              ? "total-input-dark"
                                              : "total-input-light"
                                            } `}
                                          disabled
                                          bordered={false}
                                          style={{ width: 120 }}
                                          min={0}
                                          formatter={(value) => value !== '0' ? `${value}` : ' '}
                                          parser={(value) =>
                                            value.replace("", "")
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="mb-0 pb-0 px-3" span={2}>
                                      <Tooltip title="Supprimer le produit">
                                        <Button
                                          shape="circle"
                                          icon={<DeleteOutlined />}
                                          onClick={() => {
                                            removeElement(
                                              getProductId(index)
                                            );
                                            remove(name);
                                            calculeTotalTaxes();
                                          }}
                                        />
                                      </Tooltip>
                                    </Col>
                                  </>
                                )
                              )}
                            </Row>
                          </>
                        )}
                      </Form.List>
                    </tbody>
                  </table>
                </Col>
                <Col span={8} offset={16} className="px-4">
                  <table
                    className={`table ${isDarkMode ? "table-dark" : ""} `}
                  >
                    <thead>
                      <tr>
                        <th scope="col" className="pb-3">
                          Remise :{" "}
                        </th>
                        <th scope="col">
                          <Form.Item
                            name="remise_global"
                            className="my-0 py-1"
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              formatter={(value) => `${value}%`}
                              parser={(value) => value.replace("%", "")}
                              min={0}
                              max={100}
                              onChange={() => calculateTotal()}
                            />
                          </Form.Item>
                        </th>
                      </tr>
                      <tr>
                        <th scope="col" className="pb-3">
                          Acompte :
                        </th>
                        <th scope="col">
                          <Form.Item
                            name="acompte_pourcentage"
                            className="my-0 py-1"
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              formatter={(value) => `${value}%`}
                              parser={(value) => value.replace("%", "")}
                              min={0}
                              disabled
                              max={100}
                            />
                          </Form.Item>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </Col>
                <FicheTableGain
                  selectedCurrency={selectedCurrency}
                  gains={gains}
                  isDarkMode={isDarkMode}
                ></FicheTableGain>
                <FicheTableTva
                  selectedCurrency={selectedCurrency}
                  isDarkMode={isDarkMode}
                  appliedTaxes={appliedTaxes}
                ></FicheTableTva>
                <FicheTableTotaux
                  fodec={fodec}
                  selectedCurrency={selectedCurrency}
                  isDarkMode={isDarkMode}
                  prixTotal={prixTotal}
                ></FicheTableTotaux>
              </Row>
          
          <Divider className="text-capitalize">{writtenTotal}</Divider>


          <FicheFooter entreprise={entreprise}></FicheFooter>

          <Row className="d-flex flex-row-reverse pt-5 pb-2">
            <Button 
              className="mx-2" 
              type="primary"
              onClick={productsForm.submit}
              disabled={disableForm()}
            >
              Enregistrer
            </Button>   
          </Row>
        </Form>
      </Card>
      <Modal
        visible={productsModal}
        getContainer={false}
        centered
        footer={null}
        onCancel={handleCancel}
        title={
        <div className="d-flex justify-content-between px-4">
          <div> Liste des Produits </div>
            <Dropdown.Button type="primary"  overlay={
                  <Menu>
                    <Menu.Item className="px-5" key="1" onClick={() => showProduitModal(0)}> Ajouter Un Produit</Menu.Item>
                    <Menu.Item className="px-5" key="2" onClick={() => showProduitModal(1)}>Ajouter Un Service</Menu.Item>
                    <Menu.Item className="px-5" key="3" onClick={() => showProduitModal(2)}>Ajouter Un Produit avec embalage</Menu.Item>
                  </Menu>
                }> Ajouter Un Produit 
            </Dropdown.Button>
        </div>}
        width={1000}
      >
              <Addproduct onSearchReference={handleSearchReference} onSearchLibelle={handleSearchLibelle} addprodect={handleAddproduct} onSearchGout={onSearchGout} data={productListfilter} />
        <Spin spinning={isLoading} size="large">
            <TableWithFiltres data={productListfilter} columns={ProduitColumns} />
        </Spin>
      </Modal>
       <AddProductModalFormV2 
        showModal={productModal} 
        productAdded={(action) => onCloseProductModal(action)} 
        modalTitle = {modalTitle}
        modalType = {modalType}
      ></AddProductModalFormV2>
    </Spin>
          ) : (
            <NotAuthorized></NotAuthorized>
          )}
        </>
  );
}

export default Devis;
