import { Col } from "antd";
import React, { useEffect, useState } from "react";

function FicheTableTva({ entreprise, isDarkMode, appliedTaxes  ,selectedCurrency , fodec}) {
  const [taxes,setTaxes] = useState([]);

  useEffect(() => {
    taxesChages()
  }, [selectedCurrency,appliedTaxes]);
  
  const taxesChages = () => {
    const filtredTva = [];
    const tvas = [];
    for (const element of appliedTaxes) {
      if( !filtredTva.includes(element.tvaPorucentage) ) filtredTva.push(element.tvaPorucentage);
    }
    for (const tva of filtredTva) {
      var montant = 0;
      var base = 0;
      for (const element of appliedTaxes) {
        if( tva === element.tvaPorucentage ) {
          montant += Number(element.montantTaxes);
          base += Number(element.base);
        }        
      }
      tvas.push({ tva, montant, base})
    }
    setTaxes(tvas);
  }



  return (
    <Col span="8" className="px-4">
      <table className={`table ${isDarkMode ? "table-dark" : ""} `}>
        <thead>
          <tr>
            <th scope="row">#</th>
            <th scope="col">Taux</th>
            <th scope="col">Base TVA</th>
            <th scope="col">Mnt TVA</th>
          </tr>
        </thead>
        <tbody>
          {taxes &&
            taxes.map((taxe,i) => 
              <tr key={i}>
                <th scope="row">{i+1}</th>
                <td>{Number(taxe.tva).toFixed(1)} % </td>
                <td>{Number(taxe.base).toFixed(3)} {selectedCurrency}</td>
                <td>{Number(taxe.montant).toFixed(3)} {selectedCurrency} </td>
              </tr>
              )}
        </tbody>
      </table>
    </Col>
  );
}
export default FicheTableTva;
