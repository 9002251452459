import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addCodecomptable = (data) => {
  return axios.post(API_URL + "/codecomptable/", data, { headers: authHeader() });
};

const getCodeComptable = () => {
  return axios.get(API_URL + "/codecomptable/", { headers: authHeader() });
};

const editeCodecomptable = (id, data) => {
  return axios.put(API_URL + "/codecomptable/" + id, data, { headers: authHeader() });
};

const getdatatabel = () => {
  return axios.get(API_URL + "/codecomptable/getdata", { headers: authHeader() });
};

const exportsMethodes = {
  addCodecomptable,
  getCodeComptable,
  editeCodecomptable,
  getdatatabel
};
export default exportsMethodes;