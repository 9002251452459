import {
  PrinterOutlined,
  EditOutlined,
  FilePptOutlined,
  QuestionCircleOutlined,
  CopyOutlined,
  ContainerOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Menu,
  message,
  DatePicker,
  Modal,
  Form,
  Input,
  Spin,
  Row,
  Col,
  Select,
  Tooltip,
} from "antd";
import { render, Printer, Text as ThermalText,Br,Line,Cut , Row as ThermalRow } from 'react-thermal-printer';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { environment } from "../../../env/config.env";
import PdfViewerModal from "../../../PdfViewerModal";
import Can from "../../../security/Can";
import ClientService from "../../../services/client.service";
import TicketCaisseService from "../../../services/ticketCaisse.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";
import TicketService from "../../../services/ticketCaisse.service";
import ProduitService from "../../../services/produit.service";
import FactureService from "../../../services/factureVente.service";
import UserService from "../../../services/user.service";
import Text from "antd/lib/typography/Text";


const url = environment.serverUrl;

const DashboardTicketCaisse = () => {
  const indata = window.localStorage.getItem('userData');
  const userdata = JSON.parse(indata)
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [filtredataTable, setFiltreDataTable] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [reference, setReference] = useState();
  const [users, setUsers] = useState([]);
  const [gneralfact, setGneralFact] = useState([]);
  const [statuticke,setStatuTicke]= useState([]);
  const [dataprintt, setDataprint] = useState(null);
  const [dataprint, setDatashow] = useState(null);

  useEffect(() => {
    loadBonCommande();
    loadUsers();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setFiltreDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadBonCommande = async () => {
    setLoading(true);
    await TicketCaisseService.getAllTicketCaisse(async (response) => {
      if (response.status === 200 || 204) {
        const data = response.data;
        await ClientService.getClientsNames((resp) => {
          if (response.status === 200 || 204) {
            initTableData(data, resp.data);
          } else message.error("Une erreur est survenue ! ");
        });
      } else message.error("Une erreur est survenue ! ");
      setLoading(false);
    });
  };

  const loadUsers = async () => {
    setLoading(true);
    const response = await UserService.getUsers();
    if (response.status === 200 || 204) setUsers(response.data);
    else message.error("Une erreur est survenue ! ");
    // console.log(response.data);
    setLoading(false);
  };

  const initTableData = (data, clients) => {
    const showMode = localStorage.getItem("SHOW_MODE");
    const list = [];
    for (const element of data) {
      element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
      const clientId = element.produits_ticket_caisses[0]?.clientId;
      element.clientId = clientId;
      element.client = getClientName(clientId, clients);
      if(showMode === "NO") {
        if(element.etat !== -1) list.push(element);
      } else list.push(element);
    }
    setDataTable(list);
    setFiltreDataTable(list);
  };
  const getClientName = (id, list) => {
    for (const element of list) {
      if (element.id === id) return element.nom + " " + element.prenom;
    }
  };

  const formatteEtat = (etat) => {
    switch (etat) {
      case 0:
        return "En cours";
      case 1:
        return "Activé";
      case -1:
        return "Annulé";
      default:
        return "";
    }
  };

  const changeDevisStatus = async (id, etat) => {
    const response = await TicketCaisseService.changeTicketCaisseStatus(
      id,
      etat
    );
    if (response.status === 200 || 204) {
      const msg = etat === 1 ? "Activé" : "Desactivé";
      message.info("Ticket de caisse " + msg);
    } else message.error("Une erreur est survenue ! ");
    loadBonCommande();
  };

  const navigate = (location) => {
    history.push(location);
  };

  const navigateToUpdate = (record) => {
    const id = record.id;
    history.push("/ticketCaisse", { id });
  };

  const confirmDuplication = (record) => {
    Modal.confirm({
      icon: <QuestionCircleOutlined  />,
      content: "Êtes-vous sûr de dupliquer ce ticket de caisse ?",
      okText: "Oui",
      onOk: () => duplicateData(record),
      cancelText: "Non",
    });
  };

  const confirmTransformation = (record) => {
    Modal.confirm({
      icon: <QuestionCircleOutlined  />,
      content: "Êtes-vous sûr de transférer ce ticket de caisse ?",
      okText: "Oui",
      onOk: () => transferFacture(record),
      cancelText: "Non",
    });
  };
  const duplicateData = async (record) => {
    const response = await TicketCaisseService.duplicate(record.id);
    if(response.data){
      const id  = response.data.id;
      message.success("Duplication avec succès");
      history.push("/ticketCaisse", {id});
    }else message.error("Une erreur est survenue ! ");
  }

  const transferFacture = async (ticket) => {
    setLoading(true)
    const id = ticket.id;
    try {
      let result;
      await TicketService.getTicketCaisse(id, async (response) => {
        result = await response.data
      });

      result.reference = " "
      result.produit_facture_ventes = result.produits_ticket_caisses;
      delete result.produits_ticket_caisses;
      delete result.id;
      delete result.createdAt;
      delete result.updatedAt;
      result.produit_facture_ventes.map(x =>delete x.id)

      const data={
        note:1
      }

      return FactureService.addFacture(result).then(result => {
        setLoading(false)
        TicketService.updateTicketCaisseNote(id,data)
        message.info("Ticket de caisse transférer en facture avec succès");
        loadBonCommande();
      });
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      throw error; // Re-throw the error to handle it elsewhere if needed
    }
  }

  const BonCommandes = [
    {
      title: "Référence",
      dataIndex: "reference",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    {
      title: "Date",
      dataIndex: "showDate",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Client",
      dataIndex: "client",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },

    {
      title: "Montant TTC",
      dataIndex: "montant_total",
      render: (text, record) => (
        <b>{Number(record.montant_total).toFixed(3)} TND</b>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Etat",
      dataIndex: "etat",
      render: (text, record) => (
        <Dropdown.Button
          overlay={
            <Menu disabled={!Can("17_4") || record.note === "1" }>
              <Menu.Item
                disabled={record.etat === 1}
                className="px-5"
                key="1"
                onClick={() => changeDevisStatus(record.id, 1)}
              >
                Validé
              </Menu.Item>
              <Menu.Item
                disabled={record.etat === -1}
                className="px-5"
                key="2"
                onClick={() => changeDevisStatus(record.id, -1)}
              >
                Annulé
              </Menu.Item>
            </Menu>
          }
        >
          {formatteEtat(record.etat)}
        </Dropdown.Button>
      ),
      sorter: (a, b) => a.montant_total - b.montant_total,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Dupliquer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("10_5") || record.note === "1"}
              onClick={() => confirmDuplication(record)}
              icon={<CopyOutlined />}
            />
          </Tooltip>
          <Tooltip title="Visualiser">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("17_5") || record.note === "1"}
              onClick={() => showPDFModal(record)}
              icon={<FilePptOutlined />}
            />
          </Tooltip>
          <Tooltip title="Mettre à jour">
            <Button
              disabled={record.etat === 1 || !Can("17_3") || record.note === "1"}
              className="mx-1"
              type="dashed"
              shape="circle"

              onClick={() => navigateToUpdate(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Transfert facture">
            <Button
              className="mx-1"
              type="dashed"
              disabled={!Can("10_5") || record.note === "1"}
              shape="circle"
              onClick={() => confirmTransformation(record)}
              icon={<ContainerOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (data) => {
      let test = [];
      test.push(data)
      // console.log(`selected Datas: ${data}` ,test);
      let final = {
        data: data
      }
      TicketService.getFacturationData(final).then(respond => {
        const flattenedProducts = respond.data.flatMap((item) =>
          item.produits_ticket_caisses.map((product) => ({
            ...product,
            ticketCaisseId: item.id, // Add ticketCaisseId to the flattened objects
          }))
        );

        const filterid = flattenedProducts.map(({ id, ...rest }) => rest)
        const chngeclientid = filterid.map(obj => ({ ...obj, clientId: 2 }));
        // chnage status - ticket de caisse id 
        const uniqueTicketCaisseIds = [...new Set(flattenedProducts.map(obj => obj.ticketCaisseId))];

        for (let i = 0; i < chngeclientid.length; i++) {
          for (let j = 0; j < chngeclientid.length; j++) {
            if (i != j && chngeclientid[i].produitId === chngeclientid[j].produitId) {
              chngeclientid[i].total_prix_HT = chngeclientid[i].total_prix_HT + chngeclientid[j].total_prix_HT
              chngeclientid[i].total_prix_TTC = chngeclientid[i].total_prix_TTC + chngeclientid[j].total_prix_TTC
              chngeclientid[i].quantite = chngeclientid[i].quantite + chngeclientid[j].quantite
              chngeclientid.splice(j, 1);
            }
          }
        }
        setStatuTicke(uniqueTicketCaisseIds);
        setGneralFact(chngeclientid);
      })

    },
    getCheckboxProps: (record) => (
      {
        disabled: record.note === "1", // Column configuration not to be checked
        name: record.name,
      }),
  };
  const showPDFModal = async (record) => {
    const id = record.id;

    try {
      let response;
      await TicketService.getTicketCaisse(id, async (result) => {
        response = result
      });
      const result = generateTicketprint(record, response.data);
      const show = generateTicketshow(record, response.data);
      // console.log(result)
      // Assuming generateTicketprint returns a promise
      if (result) {
        setDataprint(result);
        setDatashow(show);
        setShowModal(true);
        setReference(record.reference);
      }
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  };
  
  const downloadFile = () => {
    window.open(
      url + "Files/Vente/TicketCaisse/" + reference + ".pdf",
      "download"
    );
  };
  const userChange= (user) => {
    // console.log(dataTable)
    const datafiltred = dataTable.filter( x => x.authUserId === user )
    // console.log(datafiltred)
    setFiltreDataTable(datafiltred)
  } 

  const dateChange = (selectedDate) => {
    // console.log(selectedDate)
    if (selectedDate) {
      const formattedDate = moment(selectedDate).toISOString();
      const dataFiltered = dataTable.filter((item) => item.date.includes(formattedDate.split('T')[0]));
      setFiltreDataTable(dataFiltered);
    } else {
      setFiltreDataTable(dataTable);
    }
  };

  const factureglobale = () => {
    setLoading(true)
    try {

      const totalPriceHT = gneralfact.reduce((sum, obj) => sum + obj.total_prix_HT, 0);
      const totalPriceTTC = gneralfact.reduce((sum, obj) => sum + obj.total_prix_TTC, 0);

      const data = {
        client: 2,
        date: moment(new Date()),
        devise: "TND",
        produit_facture_ventes: gneralfact,
        remise_global: 0,
        montant_total: ((totalPriceTTC + 1).toFixed(3)).toString(),
        total_ttc: totalPriceTTC.toFixed(3),
        total_ht: totalPriceHT.toFixed(3),
        fodec: 0,
        tva: 0,
        note:1,
        totalTimbreFiscal: 1,
        etat: 0
      }

      return FactureService.addFacture(data).then(result => {
        setLoading(false)
        let final = {
          data: statuticke
        }
        TicketService.statusFacturationData(final).then(res =>{        
        message.info("Ticket de caisse sélectionner transférer en facture global avec succès");
        loadBonCommande();
      });

      });
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      throw error; // Re-throw the error to handle it elsewhere if needed
    }
  }

    // thermal ticket
  const generateTicketshow = (data, data2) => {
    if (data && data2) {
      return (
        <div>
          <Text style={{ fontSize: '16px', fontWeight: 'bold' }}>
            GAALOUL & Cie S.A.R.L. Alimentation Générale Vente En Gros
          </Text>
          <br />
          <Text>{data.client}</Text>
          <br />
          <Text>Ticket :	#{data.reference}</Text>
          <br />
          <Text>Date :{data.showDate}</Text>
          <br />
          <Line />
          {data2 && data2.produits_ticket_caisses.map((prod, index) => (
            <Row key={index}>
              <Col span={18}>
                <Text>{(prod.quantite).toString()} - {prod.libelle}</Text>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <Text>{(prod.total_prix_HT).toString()}</Text>
              </Col>
            </Row>
          ))}
          <Br />
          <Line />
          <Row >
            <Col span={18}>
              <Text>Total :</Text>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Text>{data.montant_total}</Text>
            </Col>
          </Row>
          <Br />
        </div>
      )
    } else {
      return (<> </>)
    }
  }


  const generateTicketprint = (data, data2) => {
    if (data && data2) {
      return (
        <Printer type="epson" width={42} >
          <ThermalText bold={true} size={{ width: 2, height: 2 }}> GAALOUL & Cie S.A.R.L. Alimentation Générale Vente En Gros</ThermalText>
          <ThermalText>{data.client}</ThermalText>
          <ThermalText>Ticket :	#{data.reference}</ThermalText>
          <ThermalText>Date :{data.showDate}</ThermalText>
          <Br />
          <Line />
          {data2 && data2.produits_ticket_caisses.map((prod, index) => (
            <ThermalRow key={index} left={(prod.quantite).toString() + ' - ' + prod.libelle} right={(prod.total_prix_HT).toString()} />
          ))}
          <Br />
          <Line />
          <ThermalRow left='Total' right={data.montant_total} />
          <Cut />
        </Printer>
      )
    } else {
      return (<> </>)
    }
  }

  const prinTicket = async () => {
    const data = await render(dataprintt)
    const port = await window.navigator.serial.requestPort();
    await port.open({ baudRate: 9600 });

    const writer = port.writable?.getWriter();
    if (writer != null) {
      await writer.write(data);
      writer.releaseLock();
    }
  }


  return (
    <>
      {Can("17_1") ? (
        <Spin spinning={isLoading} size="large">
          <Card
            title={"Ticket de caisse"}
            extra={
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                disabled={!Can("17_2")}
                onClick={() => navigate("/ticketCaisse")}
              >
                Ajouter un Ticket de caisse
              </Button>
            }
          >
        {userdata.authRoleId === 1 &&
              <div>
                <Row>
                  <Col span={8}> {/* Adjust the span as needed based on your layout */}

                    <Form.Item
                      label="Choisire Caisse :"
                      name="user"
                      rules={[
                        {
                          required: false,
                          message: "Champ obligatoire !",
                        },
                      ]}
                      style={{ width: '90%' }}
                    >
                      <Select
                        className="w-100 py-0"
                        size="large"
                        placeholder="Caisse"
                        showSearch={true}
                        filterOption={(input, option) => {
                          const nameMatch = option.name !== null ? option.name.toLowerCase().includes(input.toLowerCase()) : false
                          return nameMatch;
                        }}
                        onChange={(e) => userChange(e)}
                      >
                        {users.map((c) => (
                          <Select.Option key={c.id} value={c.id} name={c.nom_prenom} className="w-100">
                            <span> {c.nom_prenom}</span>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}> {/* Adjust the span as needed based on your layout */}
                    <Form.Item
                      label="Date : "
                      name="date"
                      style={{ width: '90%' }}
                    >
                      <DatePicker placeholder=" " size="large" className="w-100"  onChange={(e) => dateChange(e)}/>
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ textAlign: 'right' }}> {/* Adjust the span as needed based on your layout */}
                    <Button type="primary" onClick={() => factureglobale()} disabled={gneralfact.length === 0}>
                      Générer Facture
                    </Button>
                  </Col>
                </Row>
              </div>
      }
            <TableWithFiltres
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }} 
            data={filtredataTable} columns={BonCommandes} />
          </Card>
          <Modal
            title={
              <>
                <div className="d-flex justify-content-between ">
                  <div className="my-0">Ticket de caisse : {reference}</div>
                  <div className="px-5 ">
                    <Button
                      onClick={() => prinTicket()}
                      icon={<PrinterOutlined />}
                    >
                      Imprimer
                    </Button>
                  </div>
                </div>
              </>
            }
            centered
            visible={showModal}
            footer={null}
            width={450}
            onCancel={() => setShowModal(false)}
          >
            {showModal && dataprint}
          </Modal>
        </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
};
export default DashboardTicketCaisse;
